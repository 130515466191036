import { action, computed, flow, observable, reaction } from 'mobx';
import { xor } from 'lodash';
import { BaseStore, getOrCreateStore } from 'next-mobx-wrapper';

type CourseLoadFunction = (
  offset: number,
  limit: number,
  category: string,
  topics: string[],
  query: string,
) => Promise<CoursesQuery>;

class CoursesStore extends BaseStore {
  @observable loading = false;
  @observable hasLoadedOnce = false;
  @observable results: Array<CourseItemFragment> = [];
  @observable limit = 5;
  @observable total = 0;
  @observable offset = 0;
  @observable hydrated = false;

  @observable category = '';
  @observable topics: string[] = [];
  @observable query = '';

  @observable loadFunction?: CourseLoadFunction;

  constructor(props: any = {}) {
    super(props);
  }

  @action init = (loadFunction: CourseLoadFunction) => {
    this.loadFunction = loadFunction;
  };

  @action setCategory = (category: string) => {
    this.category = this.category === category ? '' : category;
  };

  @action setTopic = (topic: string) => {
    this.topics = xor(this.topics, [topic]);
  };

  @action setQuery = (query: string) => {
    this.query = query.trim();
  };

  @computed get allResultsLoaded() {
    return this.hasLoadedOnce && this.results.length >= this.total && !this.loading;
  }

  initReactions = flow(function* f(this: CoursesStore, initCategory = '', initTopic = '') {
    if (initCategory) {
      this.category = initCategory;
    }
    if (initTopic) {
      this.topics = [initTopic];
    }

    reaction(
      () => this.category,
      () => {
        this.filterCourseList();
      },
      { name: 'CoursesStore.categoryUpdate' },
    );

    reaction(
      () => this.topics,
      () => {
        this.filterCourseList();
      },
      { name: 'CoursesStore.topicsUpdate' },
    );

    reaction(
      () => this.query,
      () => {
        this.filterCourseList();
      },
      { name: 'CoursesStore.queryUpdate' },
    );

    this.filterCourseList();
    this.hydrated = true;
  });

  loadCourses = flow(function* f(this: CoursesStore, append = false) {
    if (!this.loadFunction) {
      throw new Error('Load function missing in CoursesStore. Make sure to pass it to init.');
    }

    this.loading = true;
    const json = yield this.loadFunction(this.offset, this.limit, this.category, this.topics, this.query);

    if (json?.courses) {
      this.total = json?.total;
      this.results = append ? this?.results?.concat(json.courses) : json.courses;
    } else {
      this.total = 0;
      this.results = [];
    }

    this.loading = false;
    this.hasLoadedOnce = true;
  });

  @action loadMoreCourses() {
    this.offset += this.limit;
    this.loadCourses(true);
  }

  @action filterCourseList = async () => {
    this.offset = 0;
    this.results = [];
    await this.loadCourses();
  };
}

export const getCoursesStore = getOrCreateStore('coursesStore', CoursesStore);
export default CoursesStore;
